<template>
  <CollectionCard>
    <CollectionCardImage :on-chain-count="onChainCount" :off-chain-count="offChainCount" :image="item.image" :is-off-chain="item.isOffChain" v-bind="$attrs">
      <template #back>
        <div class="collection-card-utility-description">{{ item.description }}</div>
      </template>
    </CollectionCardImage>
    <CollectionCardHeader name="Breeding Replenishment" :guild="item.guild" />

    <CollectionCardActions
      :items="actions"
      :is-off-chain="item.isOffChain"
      v-bind="$attrs"
      @replenishBreed="replenishBreed"
      @mint="initAction('mint')"
      @moveOffChain="initAction('move-off-chain')"
      @send="sendItem"
      v-if="!isMinting"
    />
    <CollectionCardSelectCount
      @cancel="cancel"
      :max="actionType === 'mint' ? offChainCount : onChainCount"
      :is-loading="isLoading"
      :approve-text="actionType === 'mint' ? 'Mint' : 'Approve'"
      @approve="approveAction"
      v-if="isMinting"
    />
  </CollectionCard>
</template>
<script>
import { computed, defineComponent, ref } from "vue";
import CollectionCardHeader from "../CollectionCard/CollectionCardHeader.vue";
import { TokenType } from "@/config/token-types";
import CollectionCard from "../CollectionCard/CollectionCard.vue";
import CollectionCardImage from "../CollectionCard/CollectionCardImage.vue";
import CollectionCardActions from "../CollectionCard/CollectionCardActions.vue";
import { useWeb3Store } from "@/store/web3";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { CollectionLayouts, useCollectionLayout } from "../useCollectionLayout";
import { replenishBreeding } from "@/functions/replenishBreeding";
import { mintOffChainUtilities } from "@/functions/mintOffChainUtilities";
import { moveUtilitiesOffChain } from "@/functions/moveUtilitiesOffChain";
import CollectionCardSelectCount from "../CollectionCard/CollectionCardSelectCount.vue";
import { replenishBreedingOffChain } from "@/functions/replenishBreedingOffChain";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
export default defineComponent({
  components: { CollectionCardActions, CollectionCardImage, CollectionCardSelectCount, CollectionCardHeader, CollectionCard },
  props: {
    item: Object,
  },
  setup(props) {
    const walletCollectionStore = useWalletCollectionStore()

    const offChainCount = computed(() => props.item.offChainCount)
    const onChainCount = computed(() => props.item.onChainCount)

    const actions = computed(() => {
      const items = [
        {
          name: "Replenish Breed",
          event: "replenishBreed",
          disabled: walletCollectionStore.genesis.findIndex(bull => bull.guild === props.item.guild && bull.breedingsLeft < 3) < 0
        }
      ]
      if (props.item.offChainCount > 0) {
        items.push(
          {
            name: "Mint",
            event: "mint"
          }
        )
      }
      if (props.item.offChainCount > 0) {
        items.push({
          name: "Transfer",
          event: "send"
        })
      }

      return items
    })
    const sendItem = () => {
      useModal().open({
        name: ModalName.SendUtilityModal,
        props: {
          item: props.item,
          maxAmount: 1
        }
      })
    }
    const replenishBreed = () => {
      useCollectionLayout().switchCollectionLayout(CollectionLayouts.select, {
        filter: (token, selection) => {
          if (token.type !== TokenType.GENESIS) return false
          if (token.breedingsLeft > 2) return false
          if (token.guild !== props.item.guild) return false
          return true
        },
        selectLimit: 1,
        actionText: (selection) => {
          return `Bull ${selection[0] ? selection[0].id : '?'} is selected to replenish breed`
        },
        canApprove: (selection) => selection.length > 0,
        onApprove: (selection) => {
          if (props.item.offChainCount > 0) {
            return replenishBreedingOffChain(selection[0], props.item.id)
          }
          return replenishBreeding(selection[0], props.item.id)
        }
      })
    }
   
    const isLoading = ref(false)
    const actionType = ref('')
    const isMinting = computed(() => !!actionType.value)
    
    const initAction = async (type) => {
      actionType.value = type
    }
    const cancel = () => {
      actionType.value = ''
    }

    const approveAction = async (count) => {
      isLoading.value = true
      const data = [
          {
              id: props.item.id,
              amount: count
          }
      ]
      let success;
      if (actionType.value === 'mint') {
          success = await mintOffChainUtilities(data)
      } else {
          success = await moveUtilitiesOffChain(data)
      }
      isLoading.value = false
      if (success) {
          actionType.value = ''
      }
      setTimeout(() => {
          useWalletCollectionStore().fetchCollection(true)
      }, 500)
    }

    return { 
      TokenType,
      sendItem, 
      actions, 
      replenishBreed,
      offChainCount,
      onChainCount,
      isLoading,
      actionType,
      isMinting,
      initAction,
      cancel,
      approveAction 
    }
  }
})
</script>
<style lang="scss">
</style>